@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-[#4932D5]
  }
}

.figure {
  position: relative;
}

.figure img.image-hover {
  transition-delay:0.2s;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
}
.figure:hover img.image-hover {
  opacity: 1;
}

.mono {
  font-family: 'SF Mono', sans-serif;
}

.calibre {
  font-family: 'Calibre', sans-serif;
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#F7C58A, #F7C58A);
  background-size: 0 1px;
  background-position: 0 95%;
  background-repeat: no-repeat;
  transition: background-size .2s ease-in-out;
}

.link-underline:hover {
  background-size: 100% 1px;
  background-position: 0 95%
}

.square {
  width: 50%;
  padding-bottom: 50%;
  background-color: teal;
}